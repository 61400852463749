import Vue from "vue";
import Vuex from "vuex";
import { auth } from "./auth.module";

// Modules
import themeConfig from "./theme-config";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    themeConfig,
    auth,
  },
  strict: process.env.PRODUCTION,
});
