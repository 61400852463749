import Vue from "vue";
import VueCompositionAPI, { createApp, h } from "@vue/composition-api";

import store from "./store";
import router from "./router";
import App from "./App.vue";

// Carousel

// Bootstrap
import {
  BootstrapVueIcons,
  PopoverPlugin,
  ToastPlugin,
  ModalPlugin,
  SidebarPlugin,
  TooltipPlugin,
  TabsPlugin,
} from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";

// Highlightjs
import VueHighlightJS from "vue-highlightjs";
import "highlight.js/styles/mono-blue.css";

// Apexcharts
import VueApexCharts from "vue-apexcharts";

// Other
import VueConfetti from "vue-confetti";
import VeeValidate from "vee-validate";

// Bootstrap Plugin
Vue.use(TabsPlugin);
Vue.use(TooltipPlugin);
Vue.use(SidebarPlugin);
Vue.use(PopoverPlugin);
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(BootstrapVueIcons);
Vue.use(VueConfetti);

// Highlight
Vue.use(VueHighlightJS);

// Apexchart
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

Vue.use(VeeValidate);

// Style
require("@/assets/icons/iconly/index.min.css");
require("@/assets/icons/remix-icon/index.min.css");
require("@/assets/scss/app.scss");

// Composition Api
Vue.use(VueCompositionAPI);

Vue.config.productionTip = false;
Vue.config.silent = true;

new Vue({
  router,
  store,
  render: (h) => h(App),
  port: 8081,
}).$mount("#app");
