import axios from "axios";
import lowauthHeader from "./lowauth-header";
import authHeader from "./auth-header";
//const API_URL = 'http://localhost:8081/auth/';
const API_URL = "https://api-unp.sacul.cloud/auth/";

/*
  TODO: Add a refresh token method to refresh the token if it expires after 24h
*/

class AuthService {
  async login(user) {
    const response = await axios.post(
      API_URL + "login",
      {
        username: user.username,
        password: user.password,
      },
      { headers: lowauthHeader() }
    );
    if (response.data.data.accessToken) {
      localStorage.setItem("user", JSON.stringify(response.data.data));
    }
    return response.data.data;
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(data) {
    return axios.post(
      API_URL + "register",
      {
        data: data,
      },
      { headers: lowauthHeader() }
    );
  }

  async handleMigration(discordId) {
    var data = {
      discordId: discordId,
    };
    const response = await axios.post(API_URL + "handleMigration", data, {
      headers: lowauthHeader(),
    });

    if (response.data.success) {
      return { success: true, data: response.data };
    } else {
      return { success: false };
    }
  }

  async checkDuplication(discordId) {
    var data = {
      discordId: discordId,
    };
    const response = await axios.post(API_URL + "checkDuplication", data, {
      headers: lowauthHeader(),
    });

    if (response.data.success) {
      return { success: true, isDuplicated: response.data.isDuplicated };
    } else {
      return { success: false, message: response.message };
    }
  }
}

export default new AuthService();
