import Vue from "vue";
import VueRouter from "vue-router";
import axios from "axios";
import authHeader from "../services/auth-header";
import lowauthHeader from "../services/lowauth-header";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      component: () => import("@/view/main/dashboards/"),
    },

    // MAIN
    {
      // Coming Soon
      path: "/coming-soon",
      name: "coming-soon",
      component: () => import("@/view/pages/errors/coming-soon"),
      meta: {
        layout: "full",
      },
    },
    {
      // Maintenance
      path: "/maintenance",
      name: "maintenance",
      component: () => import("@/view/pages/errors/maintenance"),
      meta: {
        layout: "full",
      },
    },
    {
      // Profile
      path: "/profile",
      name: "profile-personel-information",
      component: () => import("@/view/pages/profile"),
      meta: {
        title: "Personal Information",
        breadcrumb: [
          {
            text: "Profile",
          },
        ],
      },
    },
    {
      // Profile
      path: "/profile/application",
      name: "profile-application",
      component: () => import("@/view/pages/profile"),
      meta: {
        title: "Application",
        breadcrumb: [
          {
            text: "Profile",
          },
        ],
      },
    },
    {
      // Profile => Notifications
      path: "/profile/notifications",
      name: "profile-notifications",
      component: () => import("@/view/pages/profile"),
      meta: {
        title: "Notifications",
        breadcrumb: [
          {
            text: "Profile",
          },
        ],
      },
    },
    {
      // Profile => Password Change
      path: "/profile/security",
      name: "profile-security",
      component: () => import("@/view/pages/profile"),
      meta: {
        title: "Security Settings",
        breadcrumb: [
          {
            text: "Profile",
          },
        ],
      },
    },
    {
      // Profile => Preferences
      path: "/profile/inventory",
      name: "profile-inventory",
      component: () => import("@/view/pages/profile"),
      meta: {
        title: "Inventory",
        breadcrumb: [
          {
            text: "Profile",
          },
        ],
      },
    },
    {
      // FAQ
      path: "/support/faq",
      name: "faq",
      component: () => import("@/view/pages/faq"),
      meta: {
        title: "FAQ",
      },
    },
    {
      // Knowledge Base
      path: "/support/knowledgebase",
      name: "knowledgebase",
      component: () => import("@/view/pages/knowledge-base/knowledge-base-1"),
      meta: {
        title: "Knowledge Base",
      },
    },
    {
      // Support Tickets
      path: "/support/tickets",
      name: "support-tickets",
      beforeEnter(to, from, next) {
        window.open(
          "https://undisputednoobs.freshdesk.com/support/home",
          "_blank"
        );
      },
    },
    {
      // Live stream
      path: "/livestream",
      name: "livestream",
      beforeEnter(to, from, next) {
        window.open("https://twitch.tv/undisputednoobs", "_blank");
      },
    },
    {
      // Discord
      path: "/discord",
      name: "discord",
      beforeEnter(to, from, next) {
        window.open("https://discord.gg/undisputednoobs", "_blank");
      },
    },
    {
      // Login
      path: "/login",
      name: "login",
      component: () => import("@/view/pages/authentication/login"),
      meta: {
        layout: "full",
      },
    },
    {
      // Register
      path: "/register",
      name: "register",
      component: () => import("@/view/pages/authentication/register"),
      meta: {
        layout: "full",
      },
    },
    {
      // Welcome
      path: "/backup-login",
      name: "backup-login",
      component: () => import("@/view/pages/authentication/backup-login"),
      meta: {
        layout: "full",
      },
    },
    {
      // Logout
      path: "/logout",
      name: "logout",
      beforeEnter(to, from, next) {
        localStorage.removeItem("status");
        localStorage.removeItem("user");
        window.location.href = "/login";
      },
    },
    {
      // Reset password
      path: "/reset-password",
      name: "reset",
      component: () => import("@/view/pages/authentication/reset"),
      meta: {
        layout: "full",
      },
    },
    {
      // Account deactivated
      path: "/deactivated",
      name: "deactivated",
      component: () => import("@/view/pages/lock-screen/deactivated"),
      meta: {
        layout: "full",
      },
    },
    {
      // Password has been changed confirmation
      path: "/password-is-changed",
      name: "password-is-changed",
      component: () => import("@/view/pages/lock-screen/password"),
      meta: {
        layout: "full",
      },
    },
    {
      // unOnboarded
      path: "/not-onboarded",
      name: "not-onboarded",
      component: () => import("@/view/pages/errors/not-onboarded"),
      meta: {
        title: "Not onboarded yet • Undisputed Noobs Portal",
        layout: "full",
      },
    },
    // ------ GAMES HERE ------
    {
      // Games - Axie Infinity
      path: "/games/axieinfinity",
      name: "axieinfinity",
      component: () => import("@/view/pages/games/axieinfinity"),
      meta: {
        title: "Axie Infinity • Undisputed Noobs Portal",
      },
    },
    {
      // Games - Sunflower Land
      path: "/games/sunflowerland",
      name: "sunflowerland",
      component: () => import("@/view/pages/games/sunflowerland"),
      meta: {
        breadcrumb: [{ text: "Games", link: "/games" }],
      },
    },
    {
      // Games - Million on Mars
      path: "/games/milliononmars",
      name: "milliononmars",
      component: () => import("@/view/pages/games/milliononmars"),
      meta: {
        title: "Million on Mars • Undisputed Noobs Portal",
      },
    },
    {
      // Games - Genopets
      path: "/games/genopets",
      name: "genopets",
      component: () => import("@/view/pages/games/genopets"),
      meta: {
        title: "Genopets • Undisputed Noobs Portal",
      },
    },
    {
      // Games - Honeyland
      path: "/games/honeyland",
      name: "honeyland",
      component: () => import("@/view/pages/games/honeyland"),
      meta: {
        title: "Honeyland • Undisputed Noobs Portal",
      },
    },
    {
      // Games - Legends of Venari
      path: "/games/legendsofvenari",
      name: "legendsofvenari",
      component: () => import("@/view/pages/games/legendsofvenari"),
      meta: {
        title: "Legends of Venari • Undisputed Noobs Portal",
      },
    },
    {
      // Games - DeRace
      path: "/games/illuvium",
      name: "illuvium",
      component: () => import("@/view/pages/games/illuvium"),
      meta: {
        title: "DeRace • Undisputed Noobs Portal",
      },
    },
    {
      // Games - Gods Unchained
      path: "/games/godsunchained",
      name: "godsunchained",
      component: () => import("@/view/pages/games/godsunchained"),
      meta: {
        title: "Gods Unchained • Undisputed Noobs Portal",
      },
    },
    {
      // Games - Big Time
      path: "/games/bigtime",
      name: "bigtime",
      component: () => import("@/view/pages/games/bigtime"),
      meta: {
        title: "Big Time • Undisputed Noobs Portal",
      },
    },
    {
      // Treasury
      path: "/treasury",
      name: "treasury",
      component: () => import("@/view/pages/treasury"),
      meta: {
        title: "Treasury • Undisputed Noobs Portal",
      },
    },
    // ------ END OF GAMES ------
    {
      // Admin home
      path: "/admin",
      name: "admin",
      component: () => import("@/view/pages/admin"),
      meta: {
        title: "Admin Dashboard",
        layout: "admin",
      },
      beforeEnter(to, from, next) {
        var portalRole = JSON.parse(localStorage.getItem("user")).portalRole;
        if (portalRole != "user") {
          next();
        } else {
          next("/");
        }
      },
    },
    {
      // Admin -> Users
      path: "/admin/members",
      name: "Members List",
      component: () => import("@/view/pages/admin/members"),
      meta: {
        title: "Members List",
        layout: "admin",
      },
      beforeEnter(to, from, next) {
        var portalRole = JSON.parse(localStorage.getItem("user")).portalRole;
        if (portalRole != "user") {
          next();
        } else {
          next("/");
        }
      },
    },
    {
      // Admin -> Show User
      path: "/admin/members/user/:useruuid",
      name: "show-user",
      component: () => import("@/view/pages/admin/members/user"),
      meta: {
        title: "Show User",
        layout: "admin",
        breadcrumb: [
          { text: "Admin", path: "/admin" },
          { text: "Members", path: "/admin/members" },
        ],
      },
      beforeEnter(to, from, next) {
        var portalRole = JSON.parse(localStorage.getItem("user")).portalRole;
        if (portalRole != "user") {
          next();
        } else {
          next("/");
        }
      },
    },
    {
      // Admin -> Portal Settings
      path: "/admin/portal-settings",
      name: "portal-settings",
      component: () => import("@/view/pages/admin/portal-settings"),
      meta: {
        title: "Portal Settings",
        layout: "admin",
      },
      beforeEnter(to, from, next) {
        var portalRole = JSON.parse(localStorage.getItem("user")).portalRole;
        if (portalRole != "user") {
          next();
        } else {
          next("/");
        }
      },
    },
    {
      // Admin -> Genopets Admin
      path: "/admin/genopets",
      name: "genopets-admin",
      component: () => import("@/view/pages/admin/genopets-admin"),
      meta: {
        title: "Genopets Admin",
        layout: "admin",
      },
      beforeEnter(to, from, next) {
        var portalRole = JSON.parse(localStorage.getItem("user")).portalRole;
        if (portalRole != "user") {
          next();
        } else {
          next("/");
        }
      },
    },
    {
      // 404
      path: "*",
      component: () => import("@/view/pages/errors/404"),
      meta: {
        layout: "full",
      },
    },
    {
      // Legal - Terms of Use
      path: "/legal/terms-of-use",
      component: () => import("@/view/pages/legal/terms-of-use"),
      meta: {
        layout: "full",
      },
    },
    {
      // Legal - Privacy Policy
      path: "/legal/privacy-policy",
      component: () => import("@/view/pages/legal/privacy-policy"),
      meta: {
        layout: "full",
      },
    },
  ],
});

router.beforeEach(function (to, from, next) {
  const publicPages = [
    "/login",
    "/register",
    "/backup-login",
    "/legal/terms-of-use",
    "/legal/privacy-policy",
    "/maintenance",
  ];
  const authRequired = !publicPages.includes(to.path);
  const notOnboarded = to.path === "/not-onboarded";
  const loggedIn = localStorage.getItem("user");
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    window.location.href = "/login";
  } else {
    next();
  }
  if (loggedIn) {
    var onboardStatus = JSON.parse(localStorage.getItem("user")).isOnboarded;
    var portalRole = JSON.parse(localStorage.getItem("user")).portalRole;

    var access_key = JSON.parse(localStorage.getItem("user")).accessToken;
    var refresh_key = JSON.parse(localStorage.getItem("user")).refreshToken;

    if (!onboardStatus && !notOnboarded && !publicPages.includes(to.path)) {
      next("/not-onboarded");
    }

    if (loggedIn && authRequired) {
      var data = {
        access_key: access_key,
        refresh_key: refresh_key,
      };
      axios
        .post("https://api-unp.sacul.cloud/auth/vitals", data, {
          headers: lowauthHeader(),
        })
        .then((res) => {
          var response = res.data;

          if (!response.success || response.isExpired) {
            localStorage.removeItem("user");
            window.location.href = "/login?error=expired";
          }

          if (
            (response.success && response.maintenance) ||
            (!response.success && response.maintenance)
          ) {
            console.log("[WARN] - Maintenance mode is on");
            if (portalRole != "user") {
              next();
            } else {
              next("/maintenance");
            }
          }

          if (response.success && response.isRefreshed) {
            var newToken = response.access_key;
            var newRefresh = response.refresh_key;
            var user = JSON.parse(localStorage.getItem("user"));
            user.accessToken = newToken;
            user.refreshToken = newRefresh;
            localStorage.removeItem("user");
            localStorage.setItem("user", JSON.stringify(user));

            setTimeout(() => {
              next();
            }, 1000);
          }

          if (
            response.success &&
            !response.isExpired &&
            !response.isRefreshed
          ) {
            next();
          }
        });
    }
  }
});

export default router;
